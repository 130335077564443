import React from "react";
import {
  feature1,
  feature2,
  feature3,
  feature4,
  feature5,
  feature6,
  feature7,
  feature8,
} from "..";

function BesideJob() {
  return (
    <div className="container">
      <div className="sectionTitle">
        <h3>
          Besides All That, You{" "}
          <span className="textGrade titleArrow">Get</span>
        </h3>
      </div>
      <div className="besideFeaturesWrap">
        <div className="row">
          <div className="col-lg-3 col-md-4 col-12">
            <div className="besideFeaturesBox">
              <div className="iconWrap">
                <img src={feature1} alt="5 Days a Week" />
              </div>
              <div className="besideFeaturesName">
                <h3>5 Days a Week</h3>
                <p>
                  All weekends off to promote work-life balance and mental
                  peace.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-12">
            <div className="besideFeaturesBox">
              <div className="iconWrap">
                <img src={feature2} alt="Flexible Timings" />
              </div>
              <div className="besideFeaturesName">
                <h3>Flexible Timings</h3>
                <p>
                  To empower employees to manage things in a fast-paced life.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-12">
            <div className="besideFeaturesBox">
              <div className="iconWrap">
                <img src={feature3} alt="Onsite opportunity" />
              </div>
              <div className="besideFeaturesName">
                <h3>Onsite opportunityk</h3>
                <p>
                  National / International travel for onsite visits to work with
                  client teams.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-12">
            <div className="besideFeaturesBox">
              <div className="iconWrap">
                <img src={feature4} alt="Training" />
              </div>
              <div className="besideFeaturesName">
                <h3>Training</h3>
                <p>
                  Practical training & learning from the experts and mentors.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-12">
            <div className="besideFeaturesBox">
              <div className="iconWrap">
                <img src={feature5} alt="Rewards" />
              </div>
              <div className="besideFeaturesName">
                <h3>Rewards</h3>
                <p>
                  To value and appreciate the performance on a regular basis.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-12">
            <div className="besideFeaturesBox">
              <div className="iconWrap">
                <img src={feature6} alt="Team Building" />
              </div>
              <div className="besideFeaturesName">
                <h3>Team Building</h3>
                <p>Indoor & outdoor team building workshops and activities.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-12">
            <div className="besideFeaturesBox">
              <div className="iconWrap">
                <img src={feature7} alt="Parental Leaves" />
              </div>
              <div className="besideFeaturesName">
                <h3>Parental Leaves</h3>
                <p>
                  Get a time-off, welcome your angel & spend quality time before
                  you return.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-12">
            <div className="besideFeaturesBox">
              <div className="iconWrap">
                <img src={feature8} alt="Recreation Ground" />
              </div>
              <div className="besideFeaturesName">
                <h3>Recreation Ground</h3>
                <p>
                  Pool table, table tennis. Cricket, or anything you may ask
                  for.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BesideJob;
