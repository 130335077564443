import React, { useEffect, useState } from "react";
import { blackarrow, callImg, gradearrow, whitearrow } from "..";
import ContactForm from "../ContactForm/ContactForm";

function ExpertButton(props) {
  const [modalClass, setModalClass] = useState("");
  const [isModalActive, setIsModalActive] = useState(false);

  useEffect(() => {
    // Add or remove class to body element based on the modal state
    document.body.classList.toggle("modal-open", isModalActive);
    const sliderWithGap = document.querySelector(".slider-with-gap");
    const pinSpacer = document.querySelector(".kolbasa");
    const tabHeaderLine = document.querySelector("#disNone"); 
    const headerHide = document.querySelector("#header");

    // Remove all classes initially
    if (sliderWithGap) {
      sliderWithGap.classList.remove("removeSlickProp");
    }
    if (pinSpacer) {
      pinSpacer.classList.remove("removePinSapcerProp");
    }
    if (tabHeaderLine) {
      tabHeaderLine.classList.remove("removeHeaderTab");
    }
    if (headerHide) {
      headerHide.classList.remove("hideHeaderBar");
    }

    // Add the appropriate class based on the modal state
    if (isModalActive) {
      if (sliderWithGap) {
        sliderWithGap.classList.add("removeSlickProp");
      }
      if (pinSpacer) {
        pinSpacer.classList.add("removePinSapcerProp");
      }
      if (tabHeaderLine) {
        tabHeaderLine.classList.add("removeHeaderTab");
      }
      if (headerHide) {
        headerHide.classList.add("hideHeaderBar");
      }
    }

    return () => {
      // Clean up the class when the component unmounts
      document.body.classList.remove("modal-open");
      if (sliderWithGap) {
        sliderWithGap.classList.remove("removeSlickProp");
      }
      if (pinSpacer) {
        pinSpacer.classList.remove("removePinSapcerProp");
      }
      if (tabHeaderLine) {
        tabHeaderLine.classList.remove("removeHeaderTab");
      }
      if (headerHide) {
        headerHide.classList.remove("hideHeaderBar");
      }
    };
  }, [isModalActive]);
  const handleButtonClick = (buttonId) => {
    setModalClass(buttonId);
    setIsModalActive(true);
  };

  const closeModal = () => {
    setModalClass("out");
    setIsModalActive(false);
  };

  const handleCloseButtonClick = () => {
    closeModal();
  };

  return (
    <div className="roundedBtnWrap">
      <button
        type="button"
        className={props.rName}
        id="two"
        onClick={() => handleButtonClick("two")}
       
      >
        <span className="fa callIcon">
          <img src={callImg} alt="call Icon" />
          <div className="wifi-symbol">
            <div className="wifi-circle second"></div>
            <div className="wifi-circle third"></div>{" "}
          </div>
        </span>
        <span>{props.rText}</span>
        <div className="card-arrow arrow-icon-wrapper">
          <span className="arrow-icon">
            <img className="blackImg" loading="lazy" src={blackarrow} alt="black arrow" />
            <img className="whiteImg" loading="lazy" src={whitearrow} alt="white arrow" />
            <img className="gradeImg" loading="lazy" src={gradearrow} alt="gradient arrow" />
          </span>
          <span className="arrow-icon arrow-icon-hover">
            <img className="blackImg" loading="lazy" src={blackarrow} alt="black arrow" />
            <img className="whiteImg" loading="lazy" src={whitearrow} alt="white arrow" />
            <img className="gradeImg" loading="lazy" src={gradearrow} alt="gradient arrow" />
          </span>
        </div>
      </button>
      {isModalActive && (
        <div className="backdrop">
          <section id="innerContactSection" className={`${modalClass} `}>
            <div className=" modal-background">
              <div className="row contactWrapper modal">
                <div className="col-md-12 col-lg-10 col-12 noPad content">
                  <button
                    type="button"
                    className="close-button "
                    onClick={handleCloseButtonClick}
                  >
                    <span className="material-symbols-outlined">close</span>
                  </button>
                  <ContactForm closeModal={closeModal} />
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </div>
  );
}

export default ExpertButton;
