import React from 'react'
import { blackarrow, gradearrow, whitearrow } from '..'

function SubmitTransperentButton(props) {
  return (
    <div className="roundedBtnWrap">
      <button type="submit" className={props.rName}  disabled={props.disabled}>
        <span>{props.rText}</span>
        <div className="card-arrow arrow-icon-wrapper">
          <span className="arrow-icon">
            <img className="blackImg" loading="lazy" src={blackarrow} alt="black Arrow" />
            <img className="whiteImg" loading="lazy" src={whitearrow} alt="white Arrow" />
            <img className="gradeImg" loading="lazy" src={gradearrow} alt="gradient Arrow" />
          </span>
          <span className="arrow-icon arrow-icon-hover">
            <img className="blackImg" loading="lazy" src={blackarrow} alt="black Arrow" />
            <img className="whiteImg" loading="lazy" src={whitearrow} alt="white Arrow" />
            <img className="gradeImg" loading="lazy" src={gradearrow} alt="gradient Arrow" />
          </span>
        </div>
      </button>
    </div>
  )
}

export default SubmitTransperentButton