import React from "react";
import { team1, team2 } from "..";
import TransparentRoundedBtn from "../CTA/TransparentRoundedBtn";
import RoundedCommonBtn from "../CTA/RoundedCommonBtn";

function CreativeTeam() {
  return (
    <div className="tp-news-area black-bg fix">
      <div className="container">
        <div className="row align-items-end flex-wrap-reverse flex-md-wrap">
          <div className="col-xl-6 col-lg-6 col-md-6 mobNone">
            <div className="tp-newsletter-left-img pt-60 p-relative wow tpfadeLeft">
              <div className="flotImg box1">
                <img src={team2} alt="Team" />
              </div>
              <span className="dottetCircle"></span>
              <span className="fillCircle"></span>
              <div className="flotImg box2">
                <img src={team1} alt="Team" />
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12">
            <div className="tp-news-letter-section-box-two pt-135 pb-180 wow tpfadeUp">
              <h2 className="tp-title tp-white-text pb-10">
                What Makes{" "}
                <span className="dashArrow">
                  <span className="textGrade">Us</span>
                </span>{" "}
                <br />
                Different?
              </h2>
              <p>
                We assist both global startups and enterprises in assembling
                dedicated developer teams tailored to their specific software
                development needs. Build your team of experts, each with over 3+
                years of experience in diverse technology domains. Benefit from
                efficient communication and regular updates, ensuring timely
                delivery of your projects.
              </p>
              <div className="btnsWraps meetingBtnWrap">
                <RoundedCommonBtn rName="rAnimeBtn fillBtn" rLink="/about-version-next" rText="Our Team"/>
                <TransparentRoundedBtn rName="rAnimeBtn emptyBtn" rLink="/career" rText="Join Our Team"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreativeTeam;
