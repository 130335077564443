import React from "react";
import { Link } from "react-router-dom";
import CurrentOpeningsData from "./CurrentOpeningsData";
function JobPostCard() {
  
  return (
    <div className="row">
      {CurrentOpeningsData.map((listItem, index) => {
        return (
          <div className="col-md-6 col-12 mb-5" key={index}>
            <Link to={listItem.jdlink} target="_blank">
              <div className="positionName">
                <h4>{listItem.positionName}</h4>
              </div>
              <div className="positionNumber">
                <p>{listItem.positionCount}</p>
                <span className="material-symbols-outlined">trending_flat</span>
              </div>
            </Link>
          </div>
        );
      })}
    </div>
  );
}

export default JobPostCard;
