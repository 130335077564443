import React from "react";
import ExpertButton from "../CTA/ExpertButton";

function HealthSliderCard(props) {
  return (
    <div className="healthCardWrap">
      <h5>{props.cardTitle}</h5>
      <p>{props.cardPara}</p>
      <div className="consultationBtnWrap">
        <ExpertButton
          rName="rAnimeBtn expertBtn sliderC"
          rLink={props.cardLink}
          rText={props.btnName}
        />
      </div>
    </div>
  );
}

export default HealthSliderCard;
