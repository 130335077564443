import React from "react";

function IotProcessBlock(props) {
  return (
    <div className="col-lg-4 col-md-6 col-12 mb-5">
      <div className="blockWrap">
        <div className="iconWrap">
          <img src={props.imgSrc} alt={props.title} />
        </div>
        <div className="blockDataWrap">
          <h5>{props.title}</h5>
          <p>{props.description}</p>
        </div>
      </div>
    </div>
  );
}

export default IotProcessBlock;
