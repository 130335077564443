import React from "react";

function PageBanner(props) {
  return (
    <div className="container indusBannerContainer">
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <div className="indusLeftBox">
            <div className="indusBannerTextWrap">
              <h1>
                <span className="indusMidText">{props.PageTitle}</span>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageBanner;
