import React from "react";
import { icon1, icon2, icon3, icon4} from "..";
import { Link } from "react-router-dom";
import ExpertButton from "../CTA/ExpertButton";
function IndusExpert(props) {
  return (
    <div className="container">
      <div className="leftExpertBox">
        <h6 className="indusExpertLeftTitle"> {props.cName}</h6>
        <h2 className="indusExpertTitle">{props.cSecTitle}</h2>
      </div>
      <div className="row">
        <div className="col-md-12 col-lg-4 col-12">
          <div className="leftProfileCard">
            <div className="profileCardWrap">
              <div className="profileContainer">
                <div className="profileImgWrap">
                  <img src={props.profileImg} alt="Profile Img" />
                </div>
                <div className="profileInfoWrap">
                  <div className="profileName">
                    <h6> {props.profileName}</h6>
                  </div>
                  <div className="profileSocialWrap">
                    <p> {props.designation}</p>
                    <ul className="socialIcons">
                      <li>
                        <Link to={props.facebookLink} target="_blank">
                          <span>
                            <i className={props.facebookIcon}></i>
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to={props.linkdinLink} target="_blank">
                          <span>
                            <i className={props.linkdinIcon}></i>
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to={props.twiiterLink} target="_blank">
                          <span>
                            <i className={props.twitterIcon}></i>
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <ExpertButton rText="Talk To Experts" rName="rAnimeBtn expertBtn" />
          </div>
        </div>
        <div className="col-md-12 col-lg-8 col-12">
          <div className="rightExpertBox">
            <div className="rightBoxesWrapper">
              <div className="rightBox box1">
                <div className="boxIcon">
                  <img src={icon1} alt="Innovation" />
                </div>
                <div className="boxText">
                  <h5> {props.cardTitle1}</h5>
                  <p>{props.cardData1}</p>
                </div>
              </div>
              <div className="rightBox box2">
                <div className="boxIcon">
                  <img src={icon2} alt="Integrity" />
                </div>
                <div className="boxText">
                  <h5> {props.cardTitle2}</h5>
                  <p>{props.cardData2}</p>
                </div>
              </div>
              <div className="rightBox box3">
                <div className="boxIcon">
                  <img src={icon3} alt="Customer-Obsession" />
                </div>
                <div className="boxText">
                  <h5> {props.cardTitle3}</h5>
                  <p>{props.cardData3}</p>
                </div>
              </div>
              <div className="rightBox box4">
                <div className="boxIcon">
                  <img src={icon4} alt="Quality" />
                </div>
                <div className="boxText">
                  <h5> {props.cardTitle4}</h5>
                  <p>{props.cardData4}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndusExpert;
