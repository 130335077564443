import React from "react";
import ExpertButton from "../CTA/ExpertButton";

function WorkSliderItem(props) {
  return (
    <div className="WorkSlidings">
      <div className="col-md-8 col-lg-6">
        <div className="sliderLeft">
          <h4>
            {props.number}. {props.title}
          </h4>
          <p>{props.sliderDesc}</p>

          <ExpertButton
            rText="Know More"
            rName="rAnimeBtn expertBtn transModalBtn"
          />
        </div>
      </div>
      <div className="col-md-4 col-lg-6">
        <div className="sliderRight">
          <img
            src={props.slideImg}
            alt={props.title}
            className={props.imgClass}
          />
        </div>
      </div>
    </div>
  );
}

export default WorkSliderItem;
