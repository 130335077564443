import React from "react";
import { map } from "..";
import RegionButtons from "./RegionButtons";

function WorldMap() {
  return (
    <div className="container">
      <div className="regionMapWrap">
        <RegionButtons/>
        <div className="mapWrapper">
          <img src={map} alt="world map" />
        </div>
      </div>
    </div>
  );
}

export default WorldMap;
