import React from "react";
import PageBanner from "../components/TermsPageComponents/PageBanner";
import FaqVideo from "../components/FaqVideo/FaqVideo";
import { Helmet } from "react-helmet";
function Testimonial() {
  return (
    <>
     <Helmet>
      <title>Client Testimonials | Version Next</title>
      <meta name="description" content="Hear from satisfied clients about their experiences with Version Next. Read testimonials and success stories showcasing our innovative IT solutions." />
    </Helmet>
      <section className="testimonialPage" id="testimonialPage">
        <div className="pagesBanner">
          <PageBanner PageTitle="Our Awesome Clients" />

          <div className="shapeSvg">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15002 2408">
              <g id="Layer_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer" />
                <path
                  className="fil0"
                  d="M14999.66 160.41l0 2250.5 -15002 0 0 -2408.48c4.48,15.46 34.05,38.31 47.09,51.36 18.3,18.3 33.31,33.31 51.61,51.61l377.15 322.94c339.34,254.69 723.38,451.6 1129.12,576.25 740.24,227.38 1518.28,209.44 2250.74,-34.78 297.55,-99.2 684.02,-289.57 933.07,-476.11 87.62,-65.63 227.27,-165.69 307.34,-240.17 31.42,-29.23 114.35,-99.39 131.22,-124.58 20,4.66 99.59,47.55 124.17,59.83 42.41,21.2 81.55,40.72 122.67,61.33l125.61 58.39c133.82,59.52 249.76,112.63 391.76,169.22 238.34,95 587.91,216.82 832.54,284.94 200.63,55.86 382.28,106.97 590.69,154.3 1557.59,353.7 3775.68,425.94 5382.01,17.95 25.54,-6.48 49.29,-10.39 73.59,-16.17l373.43 -93.31c198.88,-54.19 343.18,-97.87 536.61,-159.02l312.66 -109.2c47.88,-17.88 90.5,-33.57 136.53,-51.96 82.88,-33.12 316.41,-125.5 392.57,-163.92l128.78 -59.7c23.6,-10.77 225.99,-115.13 251.04,-121.22z"
                />
              </g>
            </svg>
          </div>
        </div>
      </section>
      <section className="pageTetimonialSection">
        <div className="container">
          <div className="testimonialWrapper">
            <div className="col-lg-5 col-md-5 col-12">
              <div className="clientsDetailsWrap">
                <p>
                  "Version Next is one of the most prominent names in the
                  industry, that I was confident of shifting away from my
                  earlier US provider."
                </p>
                <div className="clientNamewWrap">
                  <h5 className="clientName">Vaishali Mautalik</h5>
                  <p className="clientCompanyName">CEO-Hexa Tech</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="clientFullImgWrap">
                <FaqVideo />
              </div>
            </div>
          </div>
          <div className="testimonialWrapper">
            <div className="col-lg-5 col-md-5 col-12">
              <div className="clientsDetailsWrap">
                <p>
                  "Version Next's engineers work directly with customers to
                  create dedicated server solutions as unique as each client -
                  solutions that help customers grow without devoting extra
                  resources to the installation and maintenance of server
                  technology. Thanks to Version Next Technologies"
                </p>
                <div className="clientNamewWrap">
                  <h5 className="clientName">Kailash Shahani</h5>
                  <p className="clientCompanyName">Morpheus Consulting</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="clientFullImgWrap">
                <FaqVideo />
              </div>
            </div>
          </div>
          <div className="testimonialWrapper">
            <div className="col-lg-5 col-md-5 col-12">
              <div className="clientsDetailsWrap">
                <p>
                  "Since day one, Version Next has provided outstanding service.
                  I have dealt with other web hosts and have never received the
                  same amount of attention from them as I have with Version
                  Next's friendly, knowledgeable and helpful staff. It's very
                  refreshing to know that some companies still value customer
                  service! Thanks"
                </p>
                <div className="clientNamewWrap">
                  <h5 className="clientName">M. K. Chouhan</h5>
                  <p className="clientCompanyName">Director -IIGL</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="clientFullImgWrap">
                <FaqVideo />
              </div>
            </div>
          </div>
          <div className="testimonialWrapper">
            <div className="col-lg-5 col-md-5 col-12">
              <div className="clientsDetailsWrap">
                <p>
                  "A big thank you to the Support guys without them I would not
                  have been able to get where I am. They never mind how daft the
                  question is they are always quick and helpful with the
                  answers."
                </p>
                <div className="clientNamewWrap">
                  <h5 className="clientName">Manisha Mishra</h5>
                  <p className="clientCompanyName">
                    Connect Tech International
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="clientFullImgWrap">
                <FaqVideo />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Testimonial;
