import React from "react";
import CardBlog from "./CardBlog";

function BlogSection() {
  return (
    <section id="blogSection">
      <div className="container">
        <div className="sectionTitle">
          <h2>
            Our <span className="textGrade titleArrow">Blog</span>
          </h2>
          <p>
            Revolutionising Industries Through Advanced Technology: Exploring
            the Impact of Software Solutions on Modern Businesses and Beyond.
          </p>
        </div>
        <div className="row">
          <CardBlog/>
        </div>
      </div>
    </section>
  );
}

export default BlogSection;
