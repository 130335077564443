import React, { useState, useRef } from "react";

function FaqVideo() {
  const [showControls, setShowControls] = useState(false);
  const [showPlayIcon, setShowPlayIcon] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const handleVideoClick = () => {
    setShowControls(true);
    setShowPlayIcon(false);

    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }

    setIsPlaying(!isPlaying);
  };

  return (
    <div className="videoWrapper">
      <div className={`playIconWrapper ${showPlayIcon ? "" : "show"}`}>
        <div className="playIcon" onClick={handleVideoClick}></div>
      </div>
      <video
        ref={videoRef}
        id="my-video"
        className={`video-js ${showControls ? "vjs-controls-enabled" : "vjs-controls-disabled"}`}
        controls={showControls ? "controls" : ""}
        preload="auto"
        poster="https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-HD.jpg"
        data-setup=""
        loop
        onClick={handleVideoClick}
      >
        <source
          src="https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-1080p.mp4"
          type="video/mp4"
        />
      </video>
    </div>
  );
}

export default FaqVideo;
