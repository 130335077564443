import React from "react";
import { circleSvg, indusFloatImg1, indusFloatImg2} from "..";

function IndusSecSection() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 col-lg-5 col-12">
          <div className="coreLeftBox">
            <h6 className="indusCoreLeftTitle">Our core services</h6>
            <h2 className="indusCoreTitle">Custom Software Development Services</h2>
            <p className="indusCoreDesc">
              We truly believe that when you choose to work with us, we become
              an extension of your team. It is a long established fact that a
              reader will be distracted by the readable content of a page when
              looking at its layout.
            </p>
          </div>
        </div>
        <div className="col-md-12 col-lg-7 col-12">
          <div className="coreRightBox">
                <div className="indusFloat1">
                    <img src={indusFloatImg1} alt="Core Services"/>
                </div>
                <span className="indusXImg"><img src={circleSvg} alt="SVG Circle"/>
                </span>
                <div className="indusFloat2">
                    <img src={indusFloatImg2} alt="Core Services"/>
                </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndusSecSection;
