import React from "react";
import { aimImg, missionImg, visionImg } from "..";

function VisionMission() {
  return (
    <div className="container">
      <div className="row justify-content-md-center">
        <div className="col-md-6 col-lg-4">
          <div className="visionWrapper">
            <div className="imgWrap">
              <img src={visionImg} alt="Our Vision" />
            </div>
            <div className="dataWrap">
              <h6>Our Vision</h6>
              <p>
                Empowering small and medium businesses through high-end
                solutions, Version Next envisions expanding their reach. We
                prioritize organizational development, building lasting customer
                relationships, and delivering desired results with
                our qualified team.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4">
          <div className="visionWrapper">
            <div className="imgWrap">
              <img src={missionImg} alt="Our Mission" />
            </div>
            <div className="dataWrap">
              <h6>Our Mission</h6>
              <p>
                Our mission is to streamline online business growth by
                developing effective processes that meet client needs. We
                prioritize transparency, supporting clients in navigating online
                competition, and expanding our network beyond
                geographical boundaries.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4">
          <div className="visionWrapper">
            <div className="imgWrap">
              <img src={aimImg} alt="Our Aim" />
            </div>
            <div className="dataWrap">
              <h6>Our Aim</h6>
              <p>
                Empower businesses with innovative solutions, fostering their
                growth and success in a competitive landscape. Through
                exceptional services and strong partnerships, we strive to
                achieve remarkable results and exceed our clients' expectations.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VisionMission;
