import React from "react";
import Milestone from "./Milestone";
import VerticalTimeLineSection from "./VerticalTimeLineSection";

const TimelineComponent = () => {
  return (
    <div className="container">
      <div className="sectionTitle">
        <h2>
          Some of Our <span className="textGrade titleArrow">Milestone</span>
        </h2>
        <p>
          Version Next Proudly Showcasing Our Remarkable Milestones: A Testament
          to Our Unwavering Commitment, Continuous Growth, and Consistent
          Success Throughout Our Journey of Excellence.
        </p>
      </div>
      <section className="gsapSection">
        <Milestone />
      </section>
      <section className="verticalTimeline">
        <VerticalTimeLineSection/>
      </section>
    </div>
  );
};

export default TimelineComponent;
