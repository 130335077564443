import React from "react";

function DevDiff(props) {
  return (
    <div className="tp-news-area black-bg fix">
      <div className="container">
        <div className="row align-items-end flex-wrap-reverse flex-md-wrap">
          <div className="col-xl-6 col-lg-6 col-md-6 mobNone">
            <div className="tp-newsletter-left-img pt-60 p-relative wow tpfadeLeft">
              <div className="flotImg box1">
                <img src={props.float1} alt="What Makes us different" />
              </div>
              <span className="dottetCircle"></span>
              <span className="fillCircle"></span>
              <div className="flotImg box2">
                <img src={props.float2} alt="What Makes us different" />
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12">
            <div className="tp-news-letter-section-box-two pt-135 pb-180 wow tpfadeUp">
              <h2 className="tp-title tp-white-text pb-10">
                {props.Char1}{" "}
                <span className="dashArrow">
                  <span className="textGrade">{props.Char2}</span>
                </span>{" "}
                <br />
                {props.Char3}
              </h2>
              <p>
                {props.diffDescription}
              </p>
              <p>{props.diffDescription2}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DevDiff;
