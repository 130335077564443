import React, { useState, useRef, useEffect } from "react";
import { testiFemale, testiMale } from "../../index";

function VerticleTestimonial() {
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const thumbRef = useRef(null);
  const containerRef = useRef(null);
  const isDragging = useRef(false);

  const handleScroll = (e) => {
    const element = e.target;
    const scrollTop = element.scrollTop;
    const scrollHeight = element.scrollHeight - element.clientHeight;
    const percentage = (scrollTop / scrollHeight) * 91.9237;
    setScrollPercentage(percentage);
  };

  // const handleThumbMouseDown = (e) => {
  //   isDragging.current = true;
  //   e.preventDefault();
  // };

  const handleThumbMouseMove = (e) => {
    if (isDragging.current) {
      requestAnimationFrame(() => {
        const container = containerRef.current;
        const containerHeight = container.clientHeight;
        const scrollHeight = container.scrollHeight - containerHeight;
        const thumbHeight = thumbRef.current.clientHeight;
        const mouseY = e.clientY - container.getBoundingClientRect().top;
        let percentage =
          ((mouseY - thumbHeight / 2) / (containerHeight - thumbHeight)) *
          91.9237;

        // Ensure percentage is within bounds
        if (percentage < 0) {
          percentage = 0;
        } else if (percentage > 91.9237) {
          percentage = 91.9237;
        }

        setScrollPercentage(percentage);
        container.scrollTop = (percentage / 91.9237) * scrollHeight;
      });
    }
  };

  const handleThumbMouseUp = (e) => {
    isDragging.current = false;
    e.preventDefault();
  };

  useEffect(() => {
    // const container = containerRef.current;

    document.addEventListener("mousemove", handleThumbMouseMove);
    document.addEventListener("mouseup", handleThumbMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleThumbMouseMove);
      document.removeEventListener("mouseup", handleThumbMouseUp);
    };
  }, [handleThumbMouseMove, handleThumbMouseUp]);
  return (
    <div
      className="verticaleSliderWrap carousel-container"
      onScroll={handleScroll}
      ref={containerRef}
      it={scrollPercentage}
    >
      <div className="clientBox">
        <div className="clientImgWrap">
          <img src={testiFemale} alt="Testimonial 1" />
        </div>
        <div className="clientNameWrap">
          <h4>Vaishali Mautalik</h4>
          <h6>CEO-Hexa Tech</h6>
        </div>
        <div className="clientWords">
          <p>
            "Version Next is one of the most prominent names in the industry,
            that I was confident of shifting away from my earlier US provider."
          </p>
        </div>
      </div>
      <div className="clientBox">
        <div className="clientImgWrap">
          <img src={testiMale} alt="Testimonial 1" />
        </div>
        <div className="clientNameWrap">
          <h4>Kailash Shahani</h4>
          <h6>Morpheus Consulting</h6>
        </div>
        <div className="clientWords">
          <p>
            "Version Next's engineers work directly with customers to create
            dedicated server solutions as unique as each client - solutions that
            help customers grow without devoting extra resources to the
            installation and maintenance of server technology. Thanks to Version
            Next Technologies"
          </p>
        </div>
      </div>
      <div className="clientBox">
        <div className="clientImgWrap">
          <img src={testiMale} alt="Testimonial 1" />
        </div>
        <div className="clientNameWrap">
          <h4>M. K. Chouhan</h4>
          <h6>Director -IIGL</h6>
        </div>
        <div className="clientWords">
          <p>
            "Since day one, Version Next has provided outstanding service. I
            have dealt with other web hosts and have never received the same
            amount of attention from them as I have with Version Next's
            friendly, knowledgeable and helpful staff. It's very refreshing to
            know that some companies still value customer service! Thanks"
          </p>
        </div>
      </div>
      <div className="clientBox">
        <div className="clientImgWrap">
          <img src={testiFemale} alt="Testimonial 1" />
        </div>
        <div className="clientNameWrap">
          <h4>Manisha Mishra</h4>
          <h6>Connect Tech International</h6>
        </div>
        <div className="clientWords">
          <p>
            "A big thank you to the Support guys without them I would not have
            been able to get where I am. They never mind how daft the question
            is they are always quick and helpful with the answers."
          </p>
        </div>
      </div>
    </div>
  );
}

export default VerticleTestimonial;
