import React from "react";

function ContactBanner() {
  return (
    <div className="container indusBannerContainer">
      <div className="row">
        <div className="col-xl-6 col-lg-12 col-md-12 col-12">
          <div className="indusLeftBox">
            <div className="indusBannerTextWrap">
              <h1>
                <span className="tindusTopText">We empower</span>
                <span className="indusMidText">global businesses</span>
                <span className="indusBtmText">to transform ideas</span>
              </h1>
            </div>
            <div className="indusBannerParaWrap">
              <p>
                Version Next is specialise in assisting global businesses and
                startups in turning their ideas into exceptional digital
                products. By leveraging our expertise and industry knowledge, we
                ensure that these products not only meet their goals but also
                deliver tangible results.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactBanner;
