import React from "react";

function DevelopBannerSection(props) {
  return (
    <div className="container indusBannerContainer">
      <div className="row">
        <div className="col-xl-6 col-lg-12 col-md-12 col-12">
          <div className="indusLeftBox">
            <div className="indusBannerTextWrap">
              <h1>
                <span className="tindusTopText">{props.line1}</span>
                <span className="indusMidText">{props.line2}</span>
                <span className="indusBtmText">{props.line3}</span>
              </h1>
            </div>
            <div className="indusBannerParaWrap">
              <p>
                {props.BannerPara}
                
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DevelopBannerSection;
