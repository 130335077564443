import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Mousewheel, Navigation } from "swiper";
import "swiper/swiper.min.css";
import {
  blackarrow,
  imgHide1,
  imgHide2,
  imgHide3,
  imgHide4,
  imgHide5,
  imgHide6,
  imgHide7,
  imgHide8,
  whitearrow,
} from "..";
SwiperCore.use([Mousewheel, Navigation]);
function ScrollTriggerPage() {
  const sliderOption = {
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    loop: false,
    spaceBetween: 0,
    slidesPerView: "3",
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 2,
      },
      991: {
        slidesPerView: 3,
      },
    },
  };
  return (
    <div className="container">
      <div className="swiper-container">
        <Swiper {...sliderOption} mousewheel={true} className="swiper-wrapper">
          <SwiperSlide className="swiper-slide">
            <div className="horizontal__item">
              <a href="/mobile-app-development" className="core-services__card relative">
                <div className="core-services__card-inner core-services__card-inner--central-knowledge">
                  <div className="core-services__card-logo bottom">
                    <h4>Mobile Apps</h4>
                  </div>
                  <div className="hoverImgWrap">
                    <img src={imgHide1} alt="VNT" width={600} height={400}/>
                  </div>
                  <div className="card-arrow arrow-icon-wrapper">
                    <span className="arrow-icon">
                      <img
                      
                        src={blackarrow}
                        alt="Vnt Arrow"
                      />
                    </span>
                    <span className="arrow-icon arrow-icon-hover">
                      <img
                      
                        src={whitearrow}
                        alt="Vnt Arrow"
                      />
                    </span>
                  </div>
                  <div className="core-services__card-text">
                    <p className="cen400 eta light">
                      At Version Next Technologies, we specialize in creating
                      cutting-edge mobile applications that cater to your
                      specific needs. Our team of skilled developers ensures
                      seamless functionality and stunning user experiences for
                      both iOS and Android platforms.
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div className="horizontal__item">
              <a href="/web-app-development" className="core-services__card relative">
                <div className="core-services__card-inner core-services__card-inner--central-knowledge">
                  <div className="core-services__card-logo bottom">
                    <h4> Web Development</h4>
                  </div>
                  <div className="hoverImgWrap webImg">
                  <img src={imgHide2} alt="VNT" width={600} height={400}/>
                  </div>
                  <div className="card-arrow arrow-icon-wrapper">
                    <span className="arrow-icon">
                      <img
                      
                        src={blackarrow}
                        alt="Vnt Arrow"
                      />
                    </span>
                    <span className="arrow-icon arrow-icon-hover">
                      <img
                      
                        src={whitearrow}
                        alt="Vnt Arrow"
                      />
                    </span>
                  </div>
                  <div className="core-services__card-text">
                    <p className="cen400 eta light">
                      Unlock the potential of your business with our top-notch
                      web development services. From creating responsive and
                      user-friendly websites to building robust web
                      applications, our team leverages the latest technologies
                      to deliver high-performance solutions that engage and
                      impress your audience.
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div className="horizontal__item">
              <a href="/design-and-graphics" className="core-services__card relative">
                <div className="core-services__card-inner core-services__card-inner--central-knowledge">
                  <div className="core-services__card-logo bottom">
                    <h4>Design and Graphics</h4>
                  </div>
                  <div className="hoverImgWrap">
                  <img src={imgHide3} alt="VNT" width={600} height={400}/>
                  </div>
                  <div className="card-arrow arrow-icon-wrapper">
                  <span className="arrow-icon">
                    <img
                    
                      src={blackarrow}
                      alt="Vnt Arrow"
                    />
                  </span>
                  <span className="arrow-icon arrow-icon-hover">
                    <img
                    
                      src={whitearrow}
                      alt="Vnt Arrow"
                    />
                  </span>
                </div>
                  <div className="core-services__card-text">
                    <p className="cen400 eta light">
                      Our talented designers and graphic artists bring your
                      ideas to life with captivating visuals and stunning
                      designs. Whether you need a logo, branding materials, or
                      eye-catching graphics for your website or marketing
                      campaigns, we deliver aesthetically pleasing solutions
                      that leave a lasting impact.
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div className="horizontal__item">
              <a href="/devops" className="core-services__card relative">
                <div className="core-services__card-inner core-services__card-inner--central-knowledge">
                  <div className="core-services__card-logo bottom">
                    <h4>DevOps</h4>
                  </div>
                  <div className="hoverImgWrap">
                  <img src={imgHide4} alt="VNT" width={600} height={400}/>
                  </div>
                  <div className="card-arrow arrow-icon-wrapper">
                  <span className="arrow-icon">
                    <img
                    
                      src={blackarrow}
                      alt="Vnt Arrow"
                    />
                  </span>
                  <span className="arrow-icon arrow-icon-hover">
                    <img
                    
                      src={whitearrow}
                      alt="Vnt Arrow"
                    />
                  </span>
                </div>
                  <div className="core-services__card-text">
                    <p className="cen400 eta light">
                      Streamline your software development and deployment
                      processes with our DevOps expertise. We help you optimize
                      collaboration, automate workflows, and enhance the
                      efficiency of your development cycle, ensuring faster and
                      more reliable software delivery.
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div className="horizontal__item">
              <a href="/internet-of-things" className="core-services__card relative">
                <div className="core-services__card-inner core-services__card-inner--central-knowledge">
                  <div className="core-services__card-logo bottom">
                    <h4>IoT</h4>
                  </div>
                  <div className="hoverImgWrap iotImg">
                  <img src={imgHide5} alt="VNT" width={600} height={400}/>
                  </div>
                  <div className="card-arrow arrow-icon-wrapper">
                    <span className="arrow-icon">
                      <img
                      
                        src={blackarrow}
                        alt="Vnt Arrow"
                      />
                    </span>
                    <span className="arrow-icon arrow-icon-hover">
                      <img
                      
                        src={whitearrow}
                        alt="Vnt Arrow"
                      />
                    </span>
                  </div>
                  <div className="core-services__card-text">
                    <p className="cen400 eta light">
                      Experience the power of connected devices with our
                      Internet of Things (IoT) solutions. We design and develop
                      IoT applications that enable seamless communication and
                      control between devices, empowering businesses to collect
                      valuable data and drive innovation across various
                      industries.
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div className="horizontal__item">
              <a href="/digital-marketing" className="core-services__card relative">
                <div className="core-services__card-inner core-services__card-inner--central-knowledge">
                  <div className="core-services__card-logo bottom">
                    <h4>Digital Marketing</h4>
                  </div>
                  <div className="hoverImgWrap digiImg">
                  <img src={imgHide6} alt="VNT" width={600} height={400}/>
                  </div>
                  <div className="card-arrow arrow-icon-wrapper">
                    <span className="arrow-icon">
                      <img
                      
                        src={blackarrow}
                        alt="Vnt Arrow"
                      />
                    </span>
                    <span className="arrow-icon arrow-icon-hover">
                      <img
                      
                        src={whitearrow}
                        alt="Vnt Arrow"
                      />
                    </span>
                  </div>
                  <div className="core-services__card-text">
                    <p className="cen400 eta light">
                      Our comprehensive digital marketing services help you
                      establish a strong online presence and reach your target
                      audience effectively. From SEO and social media marketing
                      to content creation and paid advertising, we employ
                      result-oriented strategies that maximize your brand's
                      visibility and drive conversions.
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div className="horizontal__item">
              <a href="/ai-and-ml" className="core-services__card relative">
                <div className="core-services__card-inner core-services__card-inner--central-knowledge">
                  <div className="core-services__card-logo bottom">
                    <h4>AI and ML</h4>
                  </div>
                  <div className="hoverImgWrap">
                  <img src={imgHide7} alt="VNT" width={600} height={400}/>
                  </div>
                  <div className="card-arrow arrow-icon-wrapper">
                    <span className="arrow-icon">
                      <img
                      
                        src={blackarrow}
                        alt="Vnt Arrow"
                      />
                    </span>
                    <span className="arrow-icon arrow-icon-hover">
                      <img
                      
                        src={whitearrow}
                        alt="Vnt Arrow"
                      />
                    </span>
                  </div>
                  <div className="core-services__card-text">
                    <p className="cen400 eta light">
                      Leverage the potential of artificial intelligence and
                      machine learning to gain valuable insights and automate
                      complex tasks. Our skilled team develops intelligent
                      algorithms and models that empower businesses to make
                      data-driven decisions, enhance efficiency, and deliver
                      personalized experiences.
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div className="horizontal__item">
              <a href="/quality-assurance" className="core-services__card relative">
                <div className="core-services__card-inner core-services__card-inner--central-knowledge">
                  <div className="core-services__card-logo bottom">
                    <h4>QA</h4>
                  </div>
                  <div className="hoverImgWrap">
                  <img src={imgHide8} alt="VNT" width={600} height={400}/>
                  </div>
                  <div className="card-arrow arrow-icon-wrapper">
                    <span className="arrow-icon">
                      <img
                      
                        src={blackarrow}
                        alt="Vnt Arrow"
                      />
                    </span>
                    <span className="arrow-icon arrow-icon-hover">
                      <img
                      
                        src={whitearrow}
                        alt="Vnt Arrow"
                      />
                    </span>
                  </div>
                  <div className="core-services__card-text">
                    <p className="cen400 eta light">
                      Ensure the quality and reliability of your software
                      products with our comprehensive quality assurance
                      services. Our meticulous testing processes and experienced
                      QA engineers identify and address any issues or bugs,
                      ensuring that your applications meet the highest standards
                      of performance, usability, and security.
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </SwiperSlide>
        </Swiper>
        <div className="sliderBtunsWrap">
          <div className="swiper-button-prev">
            <div className="card-arrow arrow-icon-wrapper">
              <span className="arrow-icon">
                <img src={whitearrow} alt="Vnt Arrow" />
              </span>
              <span className="arrow-icon arrow-icon-hover">
                <img src={whitearrow} alt="Vnt Arrow" />
              </span>
            </div>
          </div>
          <div className="swiper-button-next">
            <div className="card-arrow arrow-icon-wrapper">
              <span className="arrow-icon">
                <img src={whitearrow} alt="Vnt Arrow" />
              </span>
              <span className="arrow-icon arrow-icon-hover">
                <img src={whitearrow} alt="Vnt Arrow" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScrollTriggerPage;
